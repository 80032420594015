@import '../../../styles/variables.scss';

.experience {
    margin-top: -4rem;

    .job-info {
        display: flex;
    
        .dates {
            white-space: nowrap;
        }
    
        .info-position {
            width: 100%;
            margin-left: 2rem;
            margin-top: -.3rem;

            .position-dates {
                display: none;
            }
        }
    }
    
    .resume {
        position: absolute;
        height: 1.4rem;
        margin-left: 10.8rem;
    }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .info {
        .experience {
            margin-top: 0;
    
            .job-info {
                display: block;
    
                .dates {
                    display: none;
                }
    
                .info-position {
                    margin-left: 0;
                    margin-top: 0.1rem;
    
                    .position-dates {
                        display: block;
                    }
                }
            }
    
            .resume {
                margin-left: 0;
                position: relative;
            }
        }
    }
}
