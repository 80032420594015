@import '../../styles/variables.scss';

.title {
    opacity: 0;
    @include animation;

    @include blackFontStyle;
    position: fixed;
    z-index: 99;

    h1 {
        margin: 0;
    }

    h3 {
        white-space: nowrap;
        margin-bottom: 0;
    }

    h4 {
        color: $blue;
        margin-top: 0;
    }
}

/* Mobile */
@media only screen and (max-width: 1024px) {
    .br-none {
        display: none;
    }

    .title {
        width: 100%;
        position: relative;
    }
}