@import '../../styles/variables.scss';

.info {
    display: block;
    right: 4rem;
    width: 50%;
    margin-left: 50%;

    opacity: 0;
    @include animation;
    animation-delay: 0.3s;

    &:hover {
        .about:not(:hover), .experience:not(:hover), .projects:not(:hover) {
            opacity: .5;
        }
    }

    .title-about, .title-experience, .title-projects {
        display: none;
    }

    .experience, .projects {
        padding-top: 8rem;
        z-index: 0;
    
        &:hover {
            .resume, .view-all {
                h4 {
                    opacity: .5;
                }
            }
    
            .job-info:not(:hover), .project-info:not(:hover) {
                opacity: .5;
            }
        }
    
    .resume, .job-info, .view-all, .project-info {
        background: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    .resume, .view-all {
        z-index: 2;
    
        h4 {
            @include blackFontStyle;
            margin-top: 0;
            margin-bottom: 4rem;
            @include arrow;
        
            &:hover {
                opacity: 1;
                @include arrowHover;
            }
        }
    }
    
    .job-info, .project-info {
        width: 100%;
        text-align: left;
        position: relative;
        margin-bottom: 2rem;
        color: $blue;
        z-index: 1;
        @include transition;
        transition-property: opacity;
    
        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 8rem;
            z-index: -1;
            opacity: 0;
            @include transition;
            transition-property: opacity;
        }
    
        &::before {
            top: -4rem;
            background: linear-gradient(0deg, $black 75%, rgba($black, 0) 100%);
        }
    
        &::after {
            bottom: -4rem;
            background: linear-gradient(180deg, $black 75%, rgba($black, 0) 100%);
        }
    
        &:hover {
            opacity: 1;
            z-index: 2;
    
            &::before,
            &::after {
                opacity: 1;
            }
    
            .company, .name {
                @include arrowHover;
            }
        }
    
        .company, .position, .name {
            @include blackFontStyle;
        }

        .position {
            color: $blue;
        }
    
        .company, .name {
            @include arrow;
        }
    
        h3, h4 {
            @include baseFontStyle;
            margin: 0;
        }
    
        .skills {
            display: flex;
            flex-wrap: wrap;
    
            h4{
                position: relative;
                border-radius: .5rem;
                padding: .1rem .5rem;
                margin-top: .25rem;
                margin-right: .25rem;
            
                &::before {
                    content: '';
                    position: absolute;
                    opacity: .1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $blue;
                    border-radius: .5rem;
                    z-index: 1;
                }
            }
        }
    
        .tasks ul {
            margin: 0;
            padding: .5rem 1.3rem;
        }
    }
    }
    
}

@media only screen and (max-width: 1024px) {
    .info {
        width: 100%;
        height: calc(77vh - 11rem);
        display: block;
        margin: 0;

        &:hover {
            .about:not(:hover), .experience:not(:hover), .projects:not(:hover) {
                opacity: 1;
            }
        }

        .experience, .projects {
            .job-info, .project-info {
                &::before, &::after {
                    background: none;
                }

                .name::after {
                    margin-top: -0.4rem;
                    margin-left: 0;
                }
            }
        }

        .title-about, .title-experience, .title-projects {
            display: block;
            @include blackFontStyle;
            color: $blue;
            text-transform: uppercase;
            cursor: pointer;
            margin: 0;

            &:active, &.active {
                color: $red; 
            }
        }

        .mobile-dropdown:not(.open){
            display: none;
        }

        .mobile-dropdown.open {
            overflow-y: auto;
            display: block;
            padding: 2rem 0;
            height: calc(77vh - 11rem - 6.5rem);
            box-sizing: border-box;

            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                display: none;
            }

            &::before, &::after {
                content: '';
                position: absolute;
                margin-left: -1rem; 
                width: calc(100vw - 6rem);
                height: 2rem;
                z-index: 3;

                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &::before {
                margin-top: -2.2rem;
                background: linear-gradient(180deg, $black 0%, rgba($black, 0) 100%);
            }

            &::after {
                bottom: -0.1rem;
                background: linear-gradient(0deg, $black 0%, rgba($black, 0) 100%);
            }
        }
        
        .about, .experience, .projects {
            position: relative;
            padding-top: 0;

            .job-info, .project-info {
                .skills h4 {
                    font-size: $fontSize1;
                }
            }

            .resume, .view-all {
                h4 {
                    margin-bottom: 0;
                }
            }

            &:hover {
                .resume, .view-all {
                    h4 {
                        opacity: 1;
                    }
                }
        
                .job-info:not(:hover), .project-info:not(:hover) {
                    opacity: 1;
                }
            }
        }
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
    .info {
        &:hover {
            .about:not(:hover), .experience:not(:hover), .projects:not(:hover) {
                opacity: 1;
            }
        }

        .experience, .projects {
            .job-info, .project-info {
                &::before, &::after {
                    background: none;
                }
            }
        }

        .about, .experience, .projects {
            &:hover {
                .resume, .view-all {
                    h4 {
                        opacity: 1;
                    }
                }
        
                .job-info:not(:hover), .project-info:not(:hover) {
                    opacity: 1;
                }
            }
        }
    }
}
