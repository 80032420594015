@import '../../../styles/variables.scss';

.nav {
    position: fixed;
    width: 100%;
    z-index: 3;

    &:hover .about,
    &:hover .experience,
    &:hover .projects {
        opacity: 1;
    }
    
    .shadow {
        width: 200%;
        margin-left: -100%;
        height: 8rem;
        background: linear-gradient(180deg, $black 75%, rgba($black, 0) 100%);
    }

    ul {
        display: flex;
        justify-content: space-between;
        width: 50%;
        list-style: none;
        text-transform: uppercase;
        padding: 0;
        cursor: pointer;
        margin-top: -4rem;

        li {
            font-size: $fontSize1-4;
            white-space: nowrap;
            &:last-child {
                padding-right: 4rem;
              }
        }

        a {
            text-decoration: none;
            @include blackFontStyle;
            color: $blue;

            &:hover, &:active, &.active {
            color: $red; 
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .nav {
        display: none;
    }
}
