@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Source+Sans+3&display=swap');

$bodyFontFamily: 'Source Sans 3', sans-serif;
$baseFontWeight: 400;
$baseFontOpticalSizing: auto;

$blackFontFamily: 'Montserrat', sans-serif;
$blackFontWheight: 900;

/* Font-size */
$fontSize1: 1rem;
$fontSize1-4: 1.4rem;
$fontSize2: 2rem;
$fontSize3-5: 3.5rem;
$fontSize4: 4rem;  
$fontSize6: 6rem;
